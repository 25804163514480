import { useState, useEffect } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import { ATTENDANCE_SUBSCRIPTION, GET_CARD_DATA } from '../Schema/Scan';


export default function BackdropPage() {

    const [viewData, setViewData] = useState(null);

    // ========================= get data subcription ==============
    const { data, loading } = useSubscription(ATTENDANCE_SUBSCRIPTION);

    useEffect(() => {
        if (data) {
            setViewData(data?.attendanceSubscription?.shareholder_id);
            setTimeout(() => {
                setViewData(null)
            }, 7000);
        }
    }, [data])

    // =============== end sub ====================



    return (
        <div className="bg-white">
            <div className="relative isolate">
                <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                    <svg
                        className="relative left-[calc(50%-11rem)] -z-10 max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                            fillOpacity=".3"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC" />
                                <stop offset={1} stopColor="#FF80B5" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>

                {
                    viewData !== null ?
                        <>
                            <div className="w-full h-fit">
                                <div className="flex flex-col items-center justify-center ">
                                    <div className="relative">
                                        <img
                                            className="w-full"
                                            src="./background.jpg"
                                            alt=""
                                            width="100%"
                                        />
                                        <div className="absolute top-0 w-full h-full">
                                            <div className="flex flex-col items-center justify-center w-full h-full">
                                                <div className="flex flex-row justify-center w-full space-x-0">
                                                    <div className="basis-1/4"></div>
                                                    <div className="basis-1/5 flex justify-center">
                                                        <img
                                                            className="rounded-lg"
                                                            style={{ width: "70%" }}
                                                            src={viewData?.image_src}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="basis-1/4 flex justify-center">
                                                        <div className="flex flex-col justify-center space-y-8">
                                                            <p className="font-Bayon color-pink text-3xl text-center">
                                                                សូមស្វាគមន៍ការអញ្ជើញមកដល់
                                                            </p>
                                                            <p className="font-Bayon text-6xl text-center text-green-700">
                                                                {viewData?.first_name + " " + viewData?.last_name}
                                                            </p>
                                                            <p className="font-Kantumruy-regular color-blue text-2xl text-center">
                                                                {viewData?.positions}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="basis-1/4"></div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className="w-full h-fit">
                            <div className="flex flex-col items-center justify-center ">
                                <img
                                    className="w-full"
                                    src="./backdrop.jpg"
                                    alt=""
                                    width="100%"
                                />
                            </div>
                        </div>
                }

                {/* <div className="absolute inset-x-0 top-[calc(100%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)] sm:h-[42.375rem]">
                <svg
                    className="relative left-[calc(50%+3rem)] h-[23rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] "
                    viewBox="0 0 1155 678"
                >
                    <path
                        fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                        fillOpacity=".3"
                        d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                    />
                    <defs>
                        <linearGradient
                            id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                            x1="1155.49"
                            x2="-78.208"
                            y1=".177"
                            y2="474.645"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#9089FC" />
                            <stop offset={1} stopColor="#FF80B5" />
                        </linearGradient>
                    </defs>
                </svg>
            </div> */}

            </div>
        </div>
    )
}