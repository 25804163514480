import { gql } from "@apollo/client";


export const ATTENDANCE_SUBSCRIPTION = gql`
subscription AttendanceSubscription {
    attendanceSubscription {
      _id
      attendance_date
      shareholder_id {
        _id
        first_name
        last_name
        gender
        place_of_birth
        date_of_birth
        nationality_id
        image_src
        image_name
        telephone
        email
        positions
      }
      check_in
      createdAt
      updatedAt
    }
  }
`;

export const GET_CARD_DATA = gql`
query GetCardData {
  getCardData {
    totalShares
    sharesInstock
    totalPrice
    shareholders
  }
}
`
