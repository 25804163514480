import BackdropPage from './Pages/BackdropPage';
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

function App() {

  
  const httpLink = new HttpLink({
    // uri: process.env.REACT_APP_END_POINT,
    uri: "https://endpoint-goglobal-shareholder.go-globalit.com/graphql",
    // uri: "http://192.168.2.18:4100/graphql"
  });


  const wsLink = new GraphQLWsLink(createClient({
    url: "wss://endpoint-goglobal-shareholder.go-globalit.com/graphql",
    // url: "ws://192.168.2.18:4100/graphql"
  }));


  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <BackdropPage />
      </div>
    </ApolloProvider>
  );
}

export default App;
